<template>
   <div class="cooperation-warp">
       <erji-carousel></erji-carousel>
       <div class="ab-text">
            <div class="ab-title animate__bounceIn">
                <p>—— 政府合作模式 ——</p>
            </div>
            <div class="ab-desc animate__bounceIn">
                <p>
                    <!-- 全球互联网教育平台运营商，致力于构建终身教育生态系统 -->
                </p>
            </div>
       </div>
       <div class="yz-gov-con">
         <div class="gov-item">
            <img src="../../assets/images/gov01.png">
            <p class="title-gov">共建产业学院</p>
            <hr class="fenge">
            <p class="desc-gov">为区域内高新技术、新兴产业发展培训和输送人才，确保人才发展能够充分支撑产业产展，为当地社会、经济发展提供人力资源保障。</p>
         </div>
         <div class="gov-item">
            <img src="../../assets/images/gov02.png">
            <p class="title-gov">人社局项目合作</p>
            <hr class="fenge">
            <p class="desc-gov">参照国家人力资源及社会保障部新发布的新职业、特色工种，结合当地产业发展实际需求，根据当地人社制定职业培训目录，承接面向企业在职人群及未就业人群开 展的职业培训业务。</p>
         </div>
         <div class="gov-item">
            <img src="../../assets/images/gov03.png">
            <p class="title-gov">共建产业基地</p>
            <hr class="fenge">
            <p class="desc-gov">孵化、引进相结合，多头并举，促进新兴产业的形成、发展与汇聚；筹划产教融合产业园，促进产业发展，服务地方经济。</p>
         </div>
       </div>
   </div>
</template>
<script>
import ErjiCarousel from '@/components/erjiCarousel.vue'
export default {
   components: {
      ErjiCarousel
  },
  data(){
    return{

    }
  }
}
</script>
<style lang="less" scoped>
.ab-text{
    margin:30px  0px;
    .ab-title{
       text-align: center;
       font-size: 30px;
       color:#0184d2;
    }
  .ab-desc{
    margin-top:10px;
    text-align: center;
    font-size: 14px;
    color: #656d78;
  }
}
   .cooperation-warp{
     .yz-gov-con{
       width:1200px;
       margin:0 auto;
       display: flex;
        justify-content: center;/*水平居中*/
		    align-items: center;/*垂直居中*/
       .gov-item{
         width: 30%;
         height:400px;
         margin-right:20px;
         .title-gov{
           margin-top:20px;
           font-size:20px;
         }
         .desc-gov{
           font-size: 16px;
           line-height: 30px;
         }
         .fenge{
           width: 38px;
           display:inline-block;
           height: 3px;
           background: #148EFF;
           text-align: center;
           margin:20px 0px;
         }
       }
     }
   }
</style>
